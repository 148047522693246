jQuery(document).ready(function ($) {

    //Nav Functionality - Start
    $('.nav-primary .nav-toggle').click(function () {
        // $('.nav-primary .ul1').slideToggle('slow');
        // $('.nav-toggle .fa-bars').toggle();
        // $('.nav-toggle .fa-times').toggle();
        // $('header').toggleClass('fixed');
        // $('body').toggleClass('fixed');
        if ($('.nav-primary .ul1').is(':visible')) {
            $('.nav-toggle .fa-bars').show();
            $('.nav-toggle .fa-times').hide();
            $('.nav-primary .ul1').slideUp('slow');
        } else {
            $('.nav-toggle .fa-bars').hide();
            $('.nav-toggle .fa-times').show();
            $('.nav-primary .ul1').slideDown('slow');
        }
    });

    $('.nav-primary .nav-toggle').on('keypress', function (e) {
        if (e.which === 13) {
            // $('.nav-primary .ul1').slideToggle('slow');
            // $('.nav-toggle .fa-bars').toggle();
            // $('.nav-toggle .fa-times').toggle();
            // $('header').toggleClass('fixed');
            // $('body').toggleClass('fixed');
            if ($('.nav-primary .ul1').is(':visible')) {
                $('.nav-toggle .fa-bars').show();
                $('.nav-toggle .fa-times').hide();
                $('.nav-primary .ul1').slideUp('slow');
            } else {
                $('.nav-toggle .fa-bars').hide();
                $('.nav-toggle .fa-times').show();
                $('.nav-primary .ul1').slideDown('slow');
            }
        }
    });

    //Nav Functionality - End

    //Nav hover - Start 

    $(document).ready(function () {
        var dropdownMenu = $(".ul1 .li1 .a1");

        dropdownMenu.hover(function () {
            if (dropdownMenu.is(":visible")) {
                dropdownMenu.parent().toggleClass("open");
            }
        });

        var window = $(document).width();

        if (window < 992) {
            $(".dropdown .ul3").toggleClass("show");

            $(".ul4").toggleClass("show");
        }

    });

    //Nav hover - End

    //Subnav Icon Change

    $(document).ready(function () {
        var dropdownIcon = $(".dropdown .a2 span");
        var dropdownLink = $(".dropdown .a2");
        dropdownIcon.removeClass("fa-caret-down");
        dropdownIcon.addClass("fa-plus float-right pt-1 d-none d-lg-block");
        var plus = false;

        dropdownLink.click(function () {
            plus = !plus;

            if (plus) {
                $(this).children("span").removeClass("fa-plus");
                $(this).children("span").addClass("fa-minus mr-3");
            } else {
                $(this).children("span").removeClass("fa-minus mr-3");
                $(this).children("span").addClass("fa-plus");

            }
        });
    });

    //Subnav Icon Change

    //Search Functionality - Start
    $('.search-toggle').click(function () {
        $('body').addClass('search-open');
        $('.search-box').slideDown('slow');
        $('.search-box input').first().focus();
        $('.search-box .fa-times').click(function () {
            $('.search-box').slideUp('slow');
            $('body').removeClass('search-open');
        });
        // find focusable items
        var searchFocusable = $('.search-box span.fas.fa-times, .search-box .search-container a.btn-search, .search-box .search-container input');
        var searchFocusableFirst = searchFocusable.first();
        var searchFocusableLast = searchFocusable.last();
        searchFocusableLast.on('keydown', function (e) {
            if ((e.which === 9 && !e.shiftKey)) {
                e.preventDefault();
                searchFocusableFirst.focus();
            }
        });
        searchFocusableFirst.on('keydown', function (e) {
            if ((e.which === 9 && e.shiftKey)) {
                e.preventDefault();
                searchFocusableLast.focus();
            }
        });
    });

    $('.search-toggle').on('keypress', function (e) {
        if (e.which === 13) {
            $('.search-box').slideDown('slow');
            $('.search-box input').first().focus();
            $('.search-box .fa-times').on('keypress', function (e) {
                if (e.which === 13) {
                    $('.search-box').slideUp('slow');
                }
            });
            // find focusable items
            var searchFocusable = $('.search-box span.fas.fa-times, .search-box .search-container a.btn-search, .search-box .search-container input');
            var searchFocusableFirst = searchFocusable.first();
            var searchFocusableLast = searchFocusable.last();
            searchFocusableLast.on('keydown', function (e) {
                if ((e.which === 9 && !e.shiftKey)) {
                    e.preventDefault();
                    searchFocusableFirst.focus();
                }
            });
            searchFocusableFirst.on('keydown', function (e) {
                if ((e.which === 9 && e.shiftKey)) {
                    e.preventDefault();
                    searchFocusableLast.focus();
                }
            });
        }
    });
    //Search Functionality - End

    //Scroll to Top
    $(window).scroll(function () {
        if ($(this).scrollTop() > 250) {
            $(".scroll-to-top").css("opacity", "1");
        } else {
            $(".scroll-to-top").css("opacity", "0");
        }
    });
    $(".scroll-to-top").click(function () {
        $('html, body').animate({
            scrollTop: 0
        }, 1000);
        return false;
    });

    // $(window).scroll(function () {
    //   $(".scroll-to-top").removeClass("move");
    //   if ($(window).scrollTop() + $(window).height() > ($(document).height() - 100)) {
    //     $(".scroll-to-top").addClass("move");
    //   }
    // });
    //Scroll to Top END

    //Highlighted Box icon - hide image if nothing uploaded
    $(".highlighted-boxes .box .icon img[src$='{{box-icon}}']").css('display', 'none');

    //carousel
    //Hide Banner Slides
    $('.NotActiveSlide').remove();

    $('.carousel-inner div').first('div').addClass('active');

    var slideCount = $('.carousel-inner .carousel-item').length;

    for (i = 0; i < slideCount; i++) {

        var indicatorsContainer = $('.carousel-indicators');

        indicatorsContainer.append('<li data-target="#carouselIndicators" data-slide-to="' + i + '"></li>');

    }

    $('.carousel-indicators li').first().addClass('active');

    //tabs
    jQuery(document).ready(function ($) {
        var tabArray = [];

        var tabGroups = function () {
            var r = $.Deferred();
            $('.tabs').each(function () {
                tabContainerID = 'tabgroup' + $(this).index();
                tabGroup = $('.tabgroup#' + tabContainerID);
                $(this).attr('id', tabContainerID);
                var id = $(this).attr('id');
                tabArray.push(id);
            });
            return r;
        };

        var tabItems = function () {
            $.each(tabArray, function (index, value) {
                var tabContainer = $('.tabs#' + value);
                var wrapTabs = $('.tab-con', tabContainer);
                // The setup
                $('ul.tab-list', tabContainer).empty().attr('role', 'tablist');
                $('.tab-content', tabContainer).each(function () {
                    var tabHeading = $(this).find("> .tab-title").html();
                    var tabHeadingID = value + 'tab' + $(this).index();
                    $('ul.tab-list', tabContainer).append('<li><a href="#' + tabHeadingID + '">' + tabHeading + '</a></li>');
                    $(this).attr('id', tabHeadingID);
                });
                $('[role="tablist"] > li', tabContainer).attr('role', 'presentation');
                $('[role="tablist"] a', tabContainer).attr({
                    'role': 'tab',
                    'tabindex': '-1'
                });
                // Make each aria-controls correspond id of targeted section (re href)
                $('[role="tablist"] a', tabContainer).each(function () {
                    $(this).attr('aria-controls', $(this).attr('href').substring(1));
                });
                // Make the first tab selected by default and allow it focus
                $('[role="tablist"] li:first-child a', tabContainer).attr({
                    'aria-selected': 'true',
                    'tabindex': '0'
                });
                // Make each section focusable and give it the tabpanel role
                $('section', tabContainer).attr({
                    'role': 'tabpanel'
                });
                // Make first child of each panel focusable programmatically
                $('section > *:first-child', tabContainer).attr({
                    'tabindex': '0'
                });
                // Make all but the first section hidden (ARIA state and display CSS)
                $('[role="tabpanel"]:not(:first-of-type)', tabContainer).attr({
                    'aria-hidden': 'true'
                });
                // Change focus between tabs with arrow keys
                $('[role="tab"]', tabContainer).on('keydown', function (e) {
                    // define current, previous and next (possible) tabs
                    var tabCurrent = $(this);
                    var tabPrev = $(this).parents('li').prev().children('[role="tab"]');
                    var tabNext = $(this).parents('li').next().children('[role="tab"]');
                    var tabTarget;
                    // find the direction (prev or next)
                    switch (e.keyCode) {
                        case 37:
                            tabTarget = tabPrev;
                            break;
                        case 39:
                            tabTarget = tabNext;
                            break;
                        default:
                            tabTarget = false;
                            break;
                    }
                    if (tabTarget.length) {
                        tabCurrent.attr({
                            'tabindex': '-1',
                            'aria-selected': null
                        });
                        tabTarget.attr({
                            'tabindex': '0',
                            'aria-selected': true
                        }).focus();
                    }
                    // Hide panels
                    $('[role="tabpanel"]', tabContainer).attr('aria-hidden', 'true');
                    // Show panel which corresponds to target
                    $('#' + $(document.activeElement).attr('href').substring(1)).attr('aria-hidden', null);
                });
                // Handle click on tab to show + focus tabpanel
                $('[role="tab"]', tabContainer).on('click', function (e) {
                    e.preventDefault();
                    var tabID = $(this).attr('href');
                    // remove focusability and aria-selected
                    $('[role="tab"]', tabContainer).attr({
                        'tabindex': '-1',
                        'aria-selected': null
                    });
                    // replace above on clicked tab
                    $(this).attr({
                        'aria-selected': true,
                        'tabindex': '0'
                    });
                    // Hide panels
                    $('[role="tabpanel"]', tabContainer).attr('aria-hidden', 'true');
                    // show corresponding panel
                    $('section' + tabID).attr('aria-hidden', null);
                });

                var tabsContainerWidth = $('.tab-con', tabContainer).outerWidth();
                var tabsWidth = $('ul.tab-list', tabContainer).outerWidth();
                var scrollWidth = tabsWidth - tabsContainerWidth;

                if (tabsWidth > tabsContainerWidth) {
                    tabContainer.addClass('scroll-right');
                    wrapTabs.on('scroll', function () {
                        if (wrapTabs.scrollLeft() >= tabsWidth - tabsContainerWidth) {
                            tabContainer.removeClass('scroll-right');
                        } else {
                            tabContainer.addClass('scroll-right');
                        }
                        if (wrapTabs.scrollLeft() > 1) {
                            tabContainer.addClass('scroll-left');
                        } else {
                            tabContainer.removeClass('scroll-left');
                        }
                    });
                }
            });
        };
        tabGroups().done(tabItems());
    });

    //accordion
    jQuery(document).ready(function ($) {
        $('.accordion').each(function () {
            var $this = $(this);
            $this.addClass('active');
        });
        function accordionTitles() {
            var r = $.Deferred();
            var titleCount = 0;
            $('.accordion .accordion-title').each(function () {
                titleCount++;
                var $this = $(this);
                var id = 'accordion-' + titleCount;
                if ($this.hasClass('open')) {
                    $this.addClass('is-active');
                    if ($this.is('div')) {
                        $('h2', $this).wrapInner('<button onclick="return false;" aria-expanded="true" aria-controls="' + id + '">');
                    } else {
                        $this.wrapInner('<button onclick="return false;" aria-expanded="true" aria-controls="' + id + '">');
                    }
                } else {
                    if ($this.is('div')) {
                        $('h2', $this).wrapInner('<button onclick="return false;" aria-expanded="false" aria-controls="' + id + '">');
                    } else {
                        $this.wrapInner('<button onclick="return false;" aria-expanded="false" aria-controls="' + id + '">');
                    }
                }
            });
            return r;
        }
        function accordionContent() {
            var r = $.Deferred();
            var contentCount = 0;
            $('.accordion .accordion-content').each(function () {
                contentCount++;
                var $this = $(this);
                var id = 'accordion-' + contentCount;
                if ($this.prev('.accordion-title').hasClass('is-active')) {
                    $this.attr('id', id).attr('aria-hidden', 'false');
                } else {
                    $this.attr('id', id).attr('aria-hidden', 'true');
                }
            });
            return r;
        }
        function accordionButtons() {
            $('.accordion .accordion-title button').on('click', function (e) {
                e.preventDefault();
                var id = $(this).attr('aria-controls');
                var state = $(this).attr('aria-expanded') === 'false' ? true : false;
                var panel = $('.accordion-content#' + id);
                $(this).attr('aria-expanded', state);
                $(this).parents('.accordion-title').toggleClass('is-active');
                panel.attr('aria-hidden', !state);
            });
        }
        accordionTitles().done(accordionContent().done(accordionButtons()));
    });

    //news-items
    jQuery(document).ready(function ($) {

        $('.news-items .news-item:first-child').addClass('ml-md-0');

        $('.news-items .news-item:last-child').removeClass('news-mr').addClass('mr-md-0');

    });

    //Events Grid View
    var dayLink = $('.day-content a');

    var dinnerOpen = 'Dinner Open';

    dayLink.each(function () {       
        if ($(this).text().toUpperCase() === dinnerOpen.toUpperCase()) {
            $(this).parent().addClass('dinner-open');
        } else {
            $(this).parent().addClass('dinner-taken');
        }
    });

    //Meals from the Heart Button Change
    var mealsButton = $('.spfd-meals-form .btn');

    mealsButton.removeClass("btn-primary").addClass("btn-danger");

    //Subpage Header Color ]
    var subpageHeaderText = $('.subpage-header').text().toUpperCase();

    var subpageHeader = $('.subpage-header');

    if (~subpageHeaderText.indexOf("PEORIA")) {
        subpageHeader.addClass('text-primary');
    } else if (~subpageHeaderText.indexOf("SPRINGFIELD")) {
        subpageHeader.addClass('text-danger');
    }

    if (~subpageHeaderText.indexOf("BLOG")) {
        subpageHeader.addClass('d-none');
    }

    //calendar summary
    $('.events-calendar').attr("summary", "Calendar of current and upcoming events for the month");
});

